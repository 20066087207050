import React, { lazy, Suspense, useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { Person } from '@mui/icons-material';
import Grid from '@mui/material/Grid';
import { isMobileOnly } from 'react-device-detect';
import RightBg from './shared/assets/right-bg.svg';
import Logo from './shared/assets/logo.png';
import Footer from './shared/assets/footer.svg';
import Wingu from './shared/assets/wingu.png';
import Avatar from './shared/assets/avatar.png';
import {
  getAuth,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  sendPasswordResetEmail
} from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { Button, Typography } from '@mui/material';

const Login = lazy(() => import('./pages/Login'));
const Profile = lazy(() => import('./pages/Profile'));
const Result = lazy(() => import('./pages/Result'));
const SearchPage = lazy(() => import('./pages/Search'));
const Funders = lazy(() => import('./pages/Funders'));
const Contracts = lazy(() => import('./pages/Contracts'));
const Teams = lazy(() => import('./pages/Teams'));

const styles = {
  noMargin: {
    margin: '0'
  },
  background: {
    backgroundImage: `url(${RightBg})`,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#fff',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPositionX: '100%',
    overflowX: 'hidden'
  },
  user: {
    alignItems: 'flex-end',
    justifyContent: 'center',
    display: 'flex',
    color: 'white',
    flexDirection: 'column'
  }
};

const ProtectedRoute = ({ user, children }: any) => {
  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

// Configure Firebase.
const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

function App() {
  const [user, setUser] = useState<any>(null);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [authentication, setAuthState] = useState({
    authenticated: false,
    initializing: true
  });

  const app = initializeApp(config);

  const auth = getAuth(app);

  const login = (email: string, password: string) => {
    setError('');
    setSuccess('');
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        window.location.href = '/';
      })
      .catch((error) => {
        console.log(error.message);
        if (
          error.code === 'auth/invalid-email' ||
          error.code === 'auth/user-not-found' ||
          error.code === 'auth/missing-email'
        ) {
          setError('Mail inválido');
        } else if (error.code === 'auth/wrong-password') {
          setError('Contraseña incorrecta');
        } else {
          setError(error.message);
        }
      });
  };

  const changePassword = (email: string) => {
    setError('');
    setSuccess('');
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setSuccess('Mail enviado');
      })
      .catch((error) => {
        console.log(error.message);
        if (
          error.code === 'auth/invalid-email' ||
          error.code === 'auth/user-not-found' ||
          error.code === 'auth/missing-email'
        ) {
          setError('Mail inválido');
        } else if (error.code === 'auth/wrong-password') {
          setError('Contraseña incorrecta');
        } else {
          setError(error.message);
        }
      });
  };

  const logout = () => {
    signOut(auth);
    window.location.href = '/login';
  };

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        setAuthState({
          authenticated: true,
          initializing: false
        });
      } else {
        setAuthState({
          authenticated: false,
          initializing: false
        });
      }
    });
    return () => unregisterAuthObserver();
  }, [setAuthState]);

  if (authentication.initializing) {
    return <div>Cargando...</div>;
  }

  return (
    <>
      <Router>
        <Suspense fallback={<div>Cargando...</div>}>
          <div style={styles.background as React.CSSProperties}>
            <Grid
              container
              justifyContent="space-between"
              paddingTop={'20px'}
              paddingRight={'20px'}
              paddingLeft={'20px'}
            >
              <Grid item>
                <img
                  src={Logo}
                  onClick={() => (window.location.href = '/')}
                  height={isMobileOnly ? '45px' : '70px'}
                  style={{ cursor: 'pointer' }}
                />
              </Grid>
              <Grid item style={styles.user as React.CSSProperties}>
                {authentication.authenticated && (
                  <Grid container spacing={2} alignItems={'center'}>
                    <Grid item display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
                      <Typography
                        sx={{
                          margin: 0,
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          maxWidth: { xs: '100px', sm: 'initial' },
                          overflow: 'hidden'
                        }}
                      >
                        {user?.email}
                      </Typography>
                      <Button
                        style={{ margin: 0, color: 'white', padding: 0, textTransform: 'unset' }}
                        onClick={() => logout()}
                      >
                        Cerrar Sesión
                      </Button>
                    </Grid>
                    <Grid item>
                      <img src={Avatar} height="40px" />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedRoute user={user}>
                    <SearchPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="perfil"
                element={
                  <ProtectedRoute user={user}>
                    <Profile />
                  </ProtectedRoute>
                }
              />
              <Route
                path="resultado/:name"
                element={
                  <ProtectedRoute user={user}>
                    <Result />
                  </ProtectedRoute>
                }
              />
              <Route
                path="login"
                element={
                  <Login
                    login={login}
                    changePassword={changePassword}
                    error={error}
                    success={success}
                  />
                }
              />
              <Route
                path="/*"
                element={
                  <ProtectedRoute user={user}>
                    <SearchPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path="financiadores/:name"
                element={
                  <ProtectedRoute user={user}>
                    <Funders />
                  </ProtectedRoute>
                }
              />
              <Route
                path="contratos/:name"
                element={
                  <ProtectedRoute user={user}>
                    <Contracts />
                  </ProtectedRoute>
                }
              />
              <Route
                path="equipos/:name"
                element={
                  <ProtectedRoute user={user}>
                    <Teams />
                  </ProtectedRoute>
                }
              />
            </Routes>
            <Grid
              item
              paddingLeft={{ xs: '5px', sm: '20px' }}
              marginTop={'20px'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: { xs: 'column', sm: 'row' }
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                Un Proyecto de{' '}
                <a href="https://consejoderedaccion.org/" target="_blank" rel="noreferrer">
                  <img style={{ marginLeft: '10px' }} src={Footer} height="30px" />
                </a>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                Desarrollado por{' '}
                <a href="https://www.winguweb.org/" target="_blank" rel="noreferrer">
                  <img
                    style={{ marginRight: '10px', marginLeft: '10px' }}
                    src={Wingu}
                    height="20px"
                  />
                </a>
              </div>
            </Grid>
          </div>
        </Suspense>
      </Router>
    </>
  );
}

export default App;
